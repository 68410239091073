const slider = document.querySelector(".intagration__slider");
let generalZIndex = 0;
let lock = false;
if (slider) {
    let buttonPrev = slider.querySelector(".slider__prev");
    let buttonNext = slider.querySelector(".slider__next");
    let slides = slider.querySelectorAll(".slider__slide");
    slides.forEach((element, index) => {
        let zIndex = -3;
        if (index === 0) {
           element.classList.add("-prev"); 
        } else if (index === 1) {
            element.classList.add("-active");
        } else if (index === 2) {
            element.classList.add("-next");
        } else {
            element.classList.add("-other");
            element.style.zIndex = zIndex;
            zIndex = zIndex - 1;
            // element.style.zIndex = -3;
            generalZIndex = zIndex;
        }
    })
    buttonPrev.addEventListener("click", prevSlide);
    buttonNext.addEventListener("click", nextSlide);

}

function prevSlide(e) {
        if (lock) return;
        lock = true;

        setTimeout(() => {
           lock = false 
        }, 300);

        let slides = slider.querySelectorAll(".slider__slide");
        let nextSlide = document.querySelector(".slider__slide.-next");
        let activeSlide = document.querySelector(".slider__slide.-active");
        let prevSlide = document.querySelector(".slider__slide.-prev");
        

        prevSlide.classList.remove("-prev");
        prevSlide.classList.add("-active");
        prevSlide.style.zIndex = 5;

        activeSlide.classList.remove("-active");
        activeSlide.classList.add("-next");
        activeSlide.style.zIndex = 2;
        
        let arraySlides = Array.from(slides);
        let indexPrevSlide = arraySlides.indexOf(prevSlide);
        let indexNextSlide = arraySlides.indexOf(nextSlide);

        nextSlide.classList.remove("-next");
        nextSlide.classList.add("-other");
        nextSlide.style.zIndex = --generalZIndex;

        if (indexNextSlide === slides.length - 1) {
        //   nextSlide.style.zIndex = Number(slides[0].style.zIndex) + 1;
            nextSlide.style.zIndex = --generalZIndex;
        } else {
            // nextSlide.style.zIndex = Number(slides[indexNextSlide + 1].style.zIndex) + 1;
            nextSlide.style.zIndex = --generalZIndex;
        }  
       
        if (indexPrevSlide === 0) {
            slides[slides.length - 1].classList.remove("-other");
            slides[slides.length - 1].classList.add("-prev");
            slides[slides.length - 1].style.zIndex = 2;
        } else {
            slides[indexPrevSlide - 1].classList.remove("-other");
            slides[indexPrevSlide - 1].classList.add("-prev");
            slides[indexPrevSlide - 1].style.zIndex = 2;
        }
        
    }

    function nextSlide(e) {
        if (lock) return;
        lock = true;

        setTimeout(() => {
           lock = false 
        }, 300);
        let slides = slider.querySelectorAll(".slider__slide");
        let nextSlide = document.querySelector(".slider__slide.-next");
        let activeSlide = document.querySelector(".slider__slide.-active");
        let prevSlide = document.querySelector(".slider__slide.-prev");
        

        nextSlide.classList.remove("-next");
        nextSlide.classList.add("-active");
        nextSlide.style.zIndex = 5;

        activeSlide.classList.remove("-active");
        activeSlide.classList.add("-prev");
        activeSlide.style.zIndex = 2;
        
        prevSlide.classList.remove("-prev");
        prevSlide.classList.add("-other");
        prevSlide.style.zIndex = --generalZIndex;
        
        let arraySlides = Array.from(slides);
        let indexNextSlide = arraySlides.indexOf(nextSlide);

        if (indexNextSlide === slides.length - 1) {
            slides[0].classList.remove("-other");
            slides[0].classList.add("-next");
            slides[0].style.zIndex = 3;
        } else {
            slides[indexNextSlide + 1].classList.remove("-other");
            slides[indexNextSlide + 1].classList.add("-next");
            slides[indexNextSlide + 1].style.zIndex = 3;
        }
    }